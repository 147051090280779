<template>
    <div class="vedio-play wrap1200">
        <p class="tit-vedioPlay"><span class="cursor" @click="linkBack">{{seriesName}}</span> >  {{playInfo.name}}</p>
        <div class="clearfix vedio-info">
            <div class="fl"><i class="iconfont icon-yonghu"> 爱智编辑</i> <span>|</span>  {{playInfo.publishDate}}</div>
            <div class="fl" style="margin-left: 32px;"><i class="iconfont icon-chakan"> {{playInfo.playTimesZh}}次</i></div>
            <div class="fr cursor" @click="addVote"><i class="iconfont icon-zan"></i> {{playInfo.time}}</div>
        </div>
        <div class="vedio-boxs posra">
            <video id="myVideo" class="vedioPlay" muted="muted" :src="playInfo.videoPath" width="570" height="320" controls="true">
                您的浏览器不支持 video 标签。
            </video>
            <div class="bg-vedioCover"></div>
            <ul class="vedio-box">
                <li  v-for="(item, index) in contentList" :key=item.id :class="{'active': item.id == playId}" @click="changeVedio(item)"><i :class="['iconfont icon-bofang', {'icon-zhuzhuangtu': item.id== playId}]"> {{item.name}}</i></li>
                <!-- <li><i class="iconfont icon-bofang"> 互联网与教师发展（2）</i></li> -->
            </ul>
            <div class="warningTips-cover c-shadows" v-show="currentVip < minVip || !name">
                <div class="bg-shadows"></div>
                <div class="txt-infos">
                    <div v-show="currentVip < minVip && name">
                        <p>需要Lv.{{minVip}} 等级才能播放！</p>
                        <p>（您现在的等级是Lv.{{currentVip}}）</p>
                    </div>
                    <div v-show="!name" class="cursor" @click="linkTologin">
                        <p>需要登陆才能播放！</p>
                        <p>去登录</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="txt-vedio">
            <p>简介</p>
            {{playInfo.remarks}}
            <!-- 中国特色社会主义制度优势究竟“优”在哪里？要做到在守正中创新、在创新中守正，还应如何固根基、扬优势、补短板、强弱项？中央广播电视
            总台中国之声《百位专家谈中国制度》，特邀百位专家纵论优势、聚焦发展。今天（13日）推出：《高质量就业成为新时代经济发展的追求目标，
            人民美好生活的基础更坚实》，解读专家：中国社会科学研究院人口与劳动经济研究所所长张车伟。 -->
        </div>
    </div>
</template>

<style lang="scss" scope>
    @import '@/assets/css/pages/vedioPlay.scss';
</style>

<script>
    import {mapGetters} from "vuex";
    import {getLocalStorage, getVipMin, getVipLevel, getNowCookie} from '@/utils/commonBase';
    export default {
        data () {
            return {
                contentList: [],
                seriesName: this.$route.query.seriesName ? this.$route.query.seriesName : this.$route.query.name, // 系列名称
                playId: '',
                minVip: 0, // video播放最小vip
                currentVip: 0, // 当前vip
                playInfo: {}
            }
        },
        computed: {
            ...mapGetters([
                'name'
            ])
        },
        watch: {
            name() {
                var audio = document.getElementById("myVideo");
                audio.pause();
            }
        },
        created () {
            this.loadInfos();
            this.minVip = getVipMin(getLocalStorage(this.$route.query.moduleId).split(','));
            this.currentVip = getVipLevel(getLocalStorage('memberCode'));
        },
        methods: {
            loadInfos() { // 加载列表内容
                this.$http({
                    method: 'get',
                    url: '/api/yyb/v1/expModule/web/queryEntryBySeriesId',
                    params: {
                        seriesId: this.$route.query.seriesId,
                        id: this.$route.params.id,
                        moduleId: this.$route.query.moduleId,
                        pageNo: 1,
                        pageSize: 100
					}
                }).then(res => {
                    console.log('res', res);
                    this.contentList = res.data.obj.list;
                    this.changeVedio(this.contentList[0])
                }).catch(error => {
                    console.log('error', error)
                })
            },
            linkBack() {
                window.history.go(-1)
            },
            changeVedio(row) {
                this.playId = row.id;
                this.playInfo = {
                    videoPath: 'https://oss.eyyb.vip/' + row.videoPath,
                    remarks: row.remarks ? row.remarks : '暂无简介',
                    name: row.name,
                    playTimesZh: row.playTimesZh,
                    time:row.time,
                    themeId: row.expModuleId,
                    updateDate: row.updateDate
                }
                var audio = document.getElementById("myVideo");
                var playPromise = audio.play();
                if (playPromise) {
                    playPromise.then(() => {
                        // 音频加载成功
                        // 音频的播放需要耗时
                        setTimeout(() => {
                            // 后续操作
                            console.log("done");
                            audio.play();
                        }, audio.duration * 1000); // audio.duration 为音频的时长单位为秒
                    }).catch((e) => {
                        console.log("Operation is too fast, audio play fails");
                    });
                }
                if(!getNowCookie().userInfo) return;
                this.$http({ // 保存用户播放信息
                    method: 'post',
                    url: '/studymodules-api/api/yyb/v1/expModule/api/savePlayAction',
                    data: {
                        id: row.id,
                        puserId: getNowCookie().userInfo.uid,
                    }
                }).then(res => {
                    console.log('res1', res.data.success);
                    // if(res.data.success) {
                    //     this.$message.success('操作成功.')
                    // }
                }).catch(error => {
                    console.log('error', error)
                })
            },
            linkTologin() {
                this.$router.push({ 
                    name: 'login',
                })
            },
            addVote() {
                this.$http({
                    method: 'POST',
                    url: '/api/yyb/v1/expModule/web/addVote',
                    data: {
                        seriesId: this.$route.query.seriesId,
                        themeId: this.playInfo.themeId,
                        resourceId: this.playId,
                        moduleId: this.$route.query.moduleId,
					}
                }).then(res => {
                    console.log('res1', res.data.success);
                    if(res.data.success) {
                        this.$message.success('操作成功.')
                        this.playInfo.time++
                    } else {
                        this.$message.warning(res.data.msg)
                    }
                }).catch(error => {
                    console.log('error', error)
                })
            }
        }
    }
</script>

